import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Popover, PopoverBody } from "reactstrap";
import classes from "./LanguageSelector.module.css";

class LanguageSelector extends PureComponent {
  state = {
    showLanguages: false
  };

  showLanguagesHandler = () => {
    this.setState(prevState => ({
      showLanguages: !prevState.showLanguages
    }));
  };

  changeLanguageSettingHandler = language => {
    const {
      isAuth,
      onUpdateUserLanguage,
      onChangeLanguageSetting
    } = this.props;

    isAuth ? onUpdateUserLanguage(language) : onChangeLanguageSetting(language);
    this.showLanguagesHandler();
  };

  render() {
    const { showLanguages } = this.state;
    const { appLanguage } = this.props;
    let languagePopoverId = "languagePopover";
    return (
      <>
        <div
          className={[classes.Version, "d-flex align-items-center"].join(" ")}
        >
          <div  
            id={languagePopoverId}
            className={classes.VersionLink}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={this.showLanguagesHandler}
          >
            <FontAwesome name="globe" />
            &nbsp;&nbsp;
            <button className={classes.Link}>
              {appLanguage === "en" ? "English" : "Deutsch"}
            </button>
            &nbsp;
            <FontAwesome name="caret-down" />
          </div>
        </div>
        <Popover
          placement="bottom"
          isOpen={showLanguages}
          target={languagePopoverId}
          toggle={this.showLanguagesHandler}
        >
          <PopoverBody>
            <div
              className={classes.LanguageItem}
              onClick={() => this.changeLanguageSettingHandler("de")}
            >
              Deutsch
            </div>
            <div
              className={classes.LanguageItem}
              onClick={() => this.changeLanguageSettingHandler("en")}
            >
              English
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  }
}

LanguageSelector.propTypes = {
  isAuth: PropTypes.bool,
  appLanguage: PropTypes.string,
  onUpdateUserLanguage: PropTypes.func,
  onChangeLanguageSetting: PropTypes.func
};

export default LanguageSelector;
