import { all } from "redux-saga/effects";
import API from "../../api";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { errorHandler } from "../../helpers/Logger/Logger";
import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* initMediaDetailSaga(action) {
  yield all([
    put(actions.fetchMediaDetail(action.mediaId)),
    put(
      actions.fetchCurrentMediaDetailTechnicalInfo(
        action.mediaId,
        action.issuePublicationDate,
        action.appLanguage
      )
    )
  ]);
}

export function* initTechnicalURLSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      yield put(actions.changeLanguageSetting(action.appLanguage));
    }

    const response = yield API.get(mediaURL(action.mediaId));
    yield put(actions.setMediaDetail(action.mediaId, response.data));
  } catch (error) {
    yield put(actions.fetchMediaDetailFailed());
    yield logError(
      error,
      error.response,
      "fetchMediaDetail",
      mediaURL(action.mediaId)
    );
  }

  yield put(
    actions.fetchTechnicalURLInfo(
      action.mediaId,
      action.issuePublicationDate,
      action.appLanguage,
      action.md5Hash,
      action.partialBooking
    )
  );
}

export function* fetchMediaDetailSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(mediaURL(action.mediaId));
    yield put(actions.setMediaDetail(action.mediaId, response.data));
  } catch (error) {
    yield put(actions.fetchMediaDetailFailed());
    yield logError(
      error,
      error.response,
      "fetchMediaDetail",
      mediaURL(action.mediaId)
    );
  }
}

export function* fetchMediaTechnicalInfoSaga(action) {
  const technicalInfoUrl = technicalInfoURL(
    action.mediaId,
    action.selectedIssue.datum,
    action.appLanguage
  );

  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(technicalInfoUrl);
    yield put(
      actions.setMediaDetailTechnicalInfo(response.data, action.appLanguage)
    );
    yield put(actions.setMediaSelectedIssueState(action.selectedIssue));
    yield put(
      actions.setMediaDetailContactPersonState(
        response.data.ansprechpartner.filter(item => item.typId === 602)
      )
    );
  } catch (error) {
    yield put(actions.fetchMediaDetailTechnicalInfoFailed());
    yield logError(
      error,
      error.response,
      "fetchTechnicalInfo",
      technicalInfoURL
    );
  }
}

export function* fetchCurrentMediaTechnicalInfoSaga(action) {
  try {
    if (action.issuePublicationDate) {
      const technicalInfoUrl = technicalInfoURL(
        action.mediaId,
        action.issuePublicationDate,
        action.appLanguage
      );

      const token = yield call([localStorage, "getItem"], "token");
      if (token) {
        API.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      const response = yield API.get(technicalInfoUrl);

      // ToDo: To be changed later on to GetFullYear() - 1
      let selectedIssueYear = new Date(
        action.issuePublicationDate
      ).getFullYear();

      const selectedIssueResponse = yield API.get(
        datesFromYearURL(action.mediaId, selectedIssueYear)
      );
      const selectedIssue = yield getDefaultIssue(
        selectedIssueResponse.data,
        action.issuePublicationDate
      );
      yield put(
        actions.setMediaDetailTechnicalInfo(response.data, action.appLanguage)
      );
      yield put(actions.setMediaSelectedIssueState(selectedIssue));
      yield put(
        actions.setMediaDetailIssuesState(
          selectedIssueResponse.data,
          selectedIssue
        )
      );
      yield put(
        actions.setMediaDetailContactPersonState(
          response.data.ansprechpartner.filter(item => item.typId === 602)
        )
      );
    } else {
      const technicalInfoUrl = currentTechnicalInfoURL(
        action.mediaId,
        action.appLanguage
      );

      const token = yield call([localStorage, "getItem"], "token");
      if (token) {
        API.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      const response = yield API.get(technicalInfoUrl);
      const selectedIssue = yield getDefaultIssue(
        response.data.weitereTermine,
        response.data.erscheinungsTermin
      );

      yield put(
        actions.setMediaDetailTechnicalInfo(response.data, action.appLanguage)
      );
      yield put(actions.setMediaSelectedIssueState(selectedIssue));
      yield put(
        actions.setMediaDetailIssuesState(
          response.data.weitereTermine,
          selectedIssue
        )
      );
      yield put(
        actions.setMediaDetailContactPersonState(
          response.data.ansprechpartner.filter(item => item.typId === 602)
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchCurrentMediaDetailTechnicalInfoFailed());
    yield logError(
      error,
      error.response,
      "fetchCurrentMediaTechnicalInfo",
      technicalInfoURL
    );
  }
}

export function* fetchTechnicalURLInfoSaga(action) {
  try {
    if (action.issuePublicationDate) {
      const token = yield call([localStorage, "getItem"], "token");
      if (token) {
        API.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      // ToDo: To be changed later on to GetFullYear() - 1
      let selectedIssueYear = new Date(
        action.issuePublicationDate
      ).getFullYear();

      const selectedIssueResponse = yield API.get(
        datesFromYearURL(action.mediaId, selectedIssueYear)
      );
      const selectedIssue = yield getDefaultIssue(
        selectedIssueResponse.data,
        action.issuePublicationDate
      );

      const technicalInfoUrl = technicalInfoURL(
        action.mediaId,
        action.issuePublicationDate,
        action.appLanguage
      );

      const response = yield API.get(technicalInfoUrl);

      yield put(
        actions.setMediaDetailTechnicalInfo(response.data, action.appLanguage)
      );

      yield put(actions.setMediaSelectedIssueState(selectedIssue));
      yield put(
        actions.actualizeMediaDetailTechnicalInfo(
          action.mediaId,
          selectedIssue,
          action.md5Hash,
          action.appLanguage,
          action.partialBooking
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchTechnicalURLInfoFailed());
    yield logError(
      error,
      error.response,
      "fetchTechnicalURLInfo",
      technicalInfoURL
    );
  }
}

export function* actualizeMediaDetailTechnicalInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      technicalCheckURL(
        action.mediaId,
        action.selectedIssue.datum,
        action.formatMD5Hash,
        action.partialBooking,
        action.appLanguage
      )
    );


    let platzierungDruckverfahrenFarbprofile =
      response.data.platzierungDruckverfahrenFarbprofil;

    let farbProfil =
      platzierungDruckverfahrenFarbprofile.farbprofil.bezeichnung;
    let farbProfilUri =
      platzierungDruckverfahrenFarbprofile.farbprofil.profilPaketUri;
    let isAnschnitt = response.data.anzeigenFormat
      ? response.data.anzeigenFormat.anschnitt
      : 0;

    let technicalInfo = {
      heftverarbeitung: response.data.verarbeitung.bezeichnung,
      format: {
        format: response.data.anzeigenFormat.bezeichnungDE,
        endFormat: {
          breite: response.data.anzeigenFormat.breite,
          hoehe: response.data.anzeigenFormat.hoehe
        },
        platzierungsId:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.platzierungsId
            : null,
        platzierung:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.platzierung
            : null,
        ueberBund:
          response.data.anzeigenFormat && response.data.anzeigenFormat !== []
            ? response.data.anzeigenFormat.ueberBund
            : null,
        anschnitt: {
          oben: isAnschnitt ? response.data.beschnittZugaben.oben : 0,
          unten: isAnschnitt ? response.data.beschnittZugaben.unten : 0,
          links: isAnschnitt ? response.data.beschnittZugaben.links : 0,
          rechts: isAnschnitt ? response.data.beschnittZugaben.rechts : 0,
          einheit: response.data.beschnittZugaben.einheit
        },
        sicherheitsabstand: {
          oben:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          unten:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          links:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          rechts:
            response.data.verarbeitung.beschnittSicherheit
              .anschnittgefaehrdeteElemente,
          einheit: response.data.verarbeitung.beschnittSicherheit.einheit
        }
      },
      profil: {
        farbProfil: farbProfil,
        farbProfilUri: farbProfilUri,
        maxFlaechendeckung:
          platzierungDruckverfahrenFarbprofile.farbprofil
            .maximaleFlaechendeckungProzent,
        mD5Hash: platzierungDruckverfahrenFarbprofile.farbprofil.mD5Hash,
        druckverfahren:
          platzierungDruckverfahrenFarbprofile.druckverfahren.bezeichnung,
        ersterDruckenderTonNormal:
          platzierungDruckverfahrenFarbprofile.farbprofil.ersterDruckenderTonNormal,
        ersterDruckenderTonTechn:
          platzierungDruckverfahrenFarbprofile.farbprofil.ersterDruckenderTonTechn,
        letzterDifferenzierenderTon:
          platzierungDruckverfahrenFarbprofile.farbprofil.letzterDifferenzierenderTon
      },
      gueltigeDateiformate: response.data.gueltigeDateiformate,
      grenzwerte: {
        strichstaerken: {
          minStrichstaerkePositiv: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .positivSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .positiv4C
          },
          minStrichstaerkeNegativ: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .negativSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
                .negativ4C
          },
          einheit:
            platzierungDruckverfahrenFarbprofile.druckverfahren.strichStaerken
              .einheit
        },
        schriftGroessen: {
          minSchriftGroessenPositiv: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.positivSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.positiv4C
          },
          minSchriftGroessenNegativ: {
            oneCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.negativSW,
            fourCPos:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .schriftgroessen.negativ4C
          },
          einheit:
            platzierungDruckverfahrenFarbprofile.druckverfahren.schriftgroessen
              .einheit
        },
        aufloesung: {
          graustufen: {
            minimaleFarbGraustufenBildAufloesung:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.minimaleFarbGraustufenBildAufloesung,
            farbGraustufenBilderEmpfohlen:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.farbGraustufenBilderEmpfohlen,
            einheit:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.einheit
          },
          bitmapStrichBilder: {
            minimaleStrichbildAufloesung:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.minimaleStrichbildAufloesung,
            strichBilderEmpfohlen:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.strichBilderEmpfohlen,
            einheit:
              platzierungDruckverfahrenFarbprofile.druckverfahren
                .bildAufloesungen.einheit
          }
        }
      },
      bunddoppelungUmschlag: response.data.bunddoppelungUmschlag,
      bunddoppelungInnenteil: response.data.bunddoppelungInnenteil,
      geometrie: {
        breite: response.data.geometrie.breite,
        hoehe: response.data.geometrie.hoehe,
        satzspiegelBreite: response.data.geometrie.satzspiegelBreite,
        satzspiegelHoehe: response.data.geometrie.satzspiegelHoehe,
        einheit: response.data.geometrie.einheit
      },
      formatMD5Hash: action.formatMD5Hash,
      parentFormatMD5Hash: action.parentFormatMD5Hash
    };
    yield put(actions.setActualizedMediaDetailTechnicalInfo(technicalInfo));
  } catch (error) {
    yield put(actions.actualizeMediaDetailTechnicalInfoFailed());
    yield logError(
      error,
      error.response,
      "actualizeMediaDetailTechnicalInfo",
      technicalCheckURL(
        action.mediaId,
        action.selectedIssue.datum,
        action.formatMD5Hash,
        action.appLanguage
      )
    );
  }
}

export function* downloadTechnicalInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(
      downloadTechnicalInfoURL(
        action.mediumId,
        action.issueDate,
        action.appLanguage
      ),
      { responseType: "blob" }
    );
    let disposition = response.headers["content-disposition"];
    let filename =
      action.appLanguage === "en"
        ? "Medium " + action.mediumId + " - Issue"
        : "Medium " + action.mediumId + " - Heft ";

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadTechnicalInfoFail());
    yield logError(
      error,
      error.response,
      "downloadTechnicalInfo",
      downloadTechnicalInfoURL(
        action.mediumId,
        action.issueDate,
        action.appLanguage
      )
    );
  }
}

const downloadTechnicalInfoURL = (mediaId, selectedIssueDate, appLanguage) => {
  let url = apiEndpoints.TECHNICAL_INFO_DOWNLOAD_ENDPOINT;
  url = url.replace("{datum}", selectedIssueDate);
  url = url.replace("{id}", mediaId);
  url = url.replace("{sprache}", appLanguage === "en" ? "2" : "1");
  return url;
};

const technicalCheckURL = (
  mediaId,
  selectedIssueDate,
  formatMD5Hash,
  isPartialBooking,
  appLanguage
) => {
  let url = apiEndpoints.TECHNICAL_CHECK_ENDPOINT.replace("{id}", mediaId);
  url = url.replace("{datum}", selectedIssueDate);
  url = url.replace("{md5hash}", formatMD5Hash);
  url = url.replace("{istTeilbelegung}", isPartialBooking === true ? 1 : 0);
  url = url.replace("{sprache}", appLanguage);
  return url;
};

const technicalInfoURL = (mediaId, issuePublicationDate, appLanguage) => {
  let url = apiEndpoints.TECHNICAL_INFO_ENDPOINT.replace("{id}", mediaId);
  url = url.replace("{sprache}", appLanguage);
  return url.replace("{datum}", issuePublicationDate);
};

const currentTechnicalInfoURL = (mediaId, appLanguage) => {
  return apiEndpoints.TECHNICAL_INFO_CURRENT_ENDPOINT.replace(
    "{id}",
    mediaId
  ).replace("{sprache}", appLanguage);
};

const mediaURL = mediaId => {
  return apiEndpoints.MEDIA_ENDPOINT.replace("{id}", mediaId);
};

const datesFromYearURL = (mediaItemId, year) => {
  let url = apiEndpoints.DATES_FROM_YEAR_ENDPOINT.replace("{id}", mediaItemId);
  url = url.replace("{jahr}", year);
  return url;
};

const getDefaultIssue = (data, datum) => {
  let defaultItem = null;
  if (datum !== null) {
    defaultItem = data.filter(item => item.datum === datum);
  } else {
    defaultItem = data
      .filter(item => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return today - new Date(item.duSchluss) < 0;
      })
      .slice(0, 1);
  }

  return defaultItem.reduce((arr, el) => el, []);
};
