import React from "react";
import publisherLogo from "../../../assets/images/MVFP_Logo_RGB_1600x842.png";
import mswLogo from "../../../assets/images/msw-logo-RGB.png";

const Logos = () => {
  return (
    <div>
      <a rel="noopener noreferrer" target="_blank" href="https://www.mvfp.de/">
        <img src={publisherLogo} height="80" alt="MVFP" />
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a rel="noopener noreferrer" target="_blank" href="http://www.msw.de">
        <img src={mswLogo} height="30" alt="msw" />
      </a>
    </div>
  );
};

export default Logos;
