import API from "../../api";
import PrivateAPI from "../../privateApi";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { errorHandler } from "../../helpers/Logger/Logger";
import { put, call } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import * as actions from "../actions/index";
import { getJobReferenceDate } from "../../helpers/utility";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* searchJobsSaga(action) {
  try {
    let url = apiEndpoints.JOBS_SENDER_SEARCH_ENDPOINT.replace(
      "{keyword}",
      action.term
    );
    if (action.date) {
      url = url + "&referenzDatum=" + action.date;
    }

    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(url); //, { withCredentials: true })
    let searchedJobs = response.data;

    yield put(actions.updateJobs(searchedJobs));
    yield put(actions.resetFilters());
  } catch (error) {
    yield put(actions.searchJobsFail());
    logError(
      error,
      error.response,
      "searchJobs",
      apiEndpoints.JOBS_SENDER_ENDPOINT
    );
  }
}

export function* searchReceiverJobsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      apiEndpoints.JOBS_RECEIVER_SEARCH_ENDPOINT.replace(
        "{keyword}",
        action.term
      )
    ); //, { withCredentials: true })
    let searchedJobs = response.data;

    yield put(actions.updateJobs(searchedJobs));
  } catch (error) {
    yield put(actions.searchJobsFail());
    yield logError(
      error,
      error.response,
      "searchReceiverJobs",
      apiEndpoints.JOBS_RECEIVER_ENDPOINT
    );
  }
}

export function* fetchJobsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(apiEndpoints.JOBS_SENDER_ENDPOINT); //, { withCredentials: true })
    // yield put(actions.setJobs(response.data, action.gridType));
    yield put(actions.setJobsByDate(response.data, null, action.gridType));
  } catch (error) {
    const response = error ? error.response : null;
    if (response.status === 404) {
      yield put(actions.setJobs([], action.gridType));
      return;
    }
    yield put(actions.fetchJobsFailed());
    yield logError(
      error,
      error.response,
      "fetchJobs",
      apiEndpoints.JOBS_SENDER_ENDPOINT
    );
  }
}

export function* fetchJobsAzureSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.get(apiEndpoints.JOBS_AZURE_ENDPOINT); //, { withCredentials: true })
    //yield put(actions.setJobsAzure(response.data));
  } catch (error) {
    const response = error ? error.response : null;
    if (response.status === 404) {
      yield put(actions.setJobs([]));
      return;
    }
    yield put(actions.fetchJobsFailed());
    yield logError(
      error,
      error.response,
      "fetchJobs",
      apiEndpoints.JOBS_SENDER_ENDPOINT
    );
  }
}

export function* fetchJobsByDateSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let url = apiEndpoints.JOBS_SENDER_BY_DATE_ENDPOINT.replace(
      "{datum}",
      action.date
    );
    if (action.term) {
      url = url + "&search=" + action.term;
    }
    const response = yield API.get(url); //, { withCredentials: true })
    yield put(
      actions.setJobsByDate(response.data, action.dateFilter, action.gridType)
    );
  } catch (error) {
    const response = error ? error.response : null;
    if (response.status === 404) {
      yield put(actions.setJobs([], action.gridType));
      return;
    }
    yield put(actions.fetchJobsByDateFailed());
    yield logError(
      error,
      error.response,
      "fetchJobs",
      apiEndpoints.JOBS_SENDER_ENDPOINT
    );
  }
}

export function* fetchReceiverJobsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(apiEndpoints.JOBS_RECEIVER_ENDPOINT); //, { withCredentials: true })
    let receivedJobs = response.data.filter(item => item.statusId >= 81);
    //yield put(actions.setJobs(receivedJobs, action.gridType));
    yield put(
      actions.setReceiverJobsByDate(receivedJobs, null, action.gridType)
    );
  } catch (error) {
    const response = error ? error.response : null;
    if (response.status === 404) {
      yield put(actions.setJobs([], action.gridType));
      return;
    }
    yield put(actions.fetchJobsFailed());
    yield logError(
      error,
      error.response,
      "fetchReceiverJobs",
      apiEndpoints.JOBS_RECEIVER_ENDPOINT
    );
  }
}

export function* fetchReceiverJobsByDateSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let url = apiEndpoints.JOBS_RECEIVER_BY_DATE_ENDPOINT.replace(
      "{datum}",
      action.date
    );
    if (action.term) {
      url = url + "&search=" + action.term;
    }
    const response = yield API.get(url); //, { withCredentials: true })
    let receivedJobs = response.data.filter(item => item.statusId >= 81);
    yield put(
      actions.setReceiverJobsByDate(
        receivedJobs,
        action.dateFilter,
        action.gridType
      )
    );
  } catch (error) {
    const response = error ? error.response : null;
    if (response.status === 404) {
      yield put(actions.setJobs([], action.gridType));
      return;
    }
    yield put(actions.fetchJobsFailed());
    yield logError(
      error,
      error.response,
      "fetchReceiverJobs",
      apiEndpoints.JOBS_RECEIVER_ENDPOINT
    );
  }
}

export function* approveJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.get(sendJobURL(action.value)); //, { withCredentials: true })
    yield put(actions.fetchJobs());
  } catch (error) {
    yield put(actions.sendJobFailed());
    yield logError(
      error,
      error.response,
      "approveJob",
      sendJobURL(action.value)
    );
  }
}

export function* downloadJobSaga(action) {
  yield put(actions.downloadJobFile(action.jobId, action.appLanguage));
}

export function* downloadJobFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(
      downloadJobURL(action.jobId, action.appLanguage),
      { responseType: "blob" }
    );
    let disposition = response.headers["content-disposition"];
    let filename = action.jobId + ".zip";

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadJobFileFail());
    yield logError(
      error,
      error.response,
      "downloadJobFile",
      downloadJobURL(action.jobId, action.appLanguage)
    );
  }
}

export function* setJobAsReceivedSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.get(receiveJobURL(action.jobId));
    yield put(actions.fetchReceiverJobs());
  } catch (error) {
    yield put(actions.setJobAsReceivedFailed(action.jobId));
    yield logError(
      error,
      error.response,
      "setJobAsReceived",
      receiveJobURL(action.jobId)
    );
  }
}

export function* downloadReportByTypeSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(
      downloadReportURL(action.jobId, action.reportType),
      { responseType: "blob" }
    );

    let disposition = response.headers["content-disposition"];
    let filename = action.jobId + "." + action.reportType;

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadReportFileFail(action.reportType));
    yield logError(
      error,
      error.response,
      "downloadReportByType",
      downloadReportURL(action.jobId, action.reportType)
    );
  }
}

export function* openReportHTMLSaga(action) {
  const htmlLanguage = action.appLanguage === "en" ? "html-en" : "html";
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const response = yield API.get(
      downloadReportURL(action.jobId, htmlLanguage),
      {
        responseType: "blob"
      }
    );

    let disposition = response.headers["content-disposition"];
    let filename = action.jobId + ".html";

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    var blob = new Blob([response.data], {
      type: "text/html"
    });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");

      if ("URL" in window) {
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
      } else {
        link.setAttribute("href", window.webkitURL.createObjectURL(blob));
      }
      link.click();
    }
  } catch (error) {
    yield put(actions.downloadReportFileFail(htmlLanguage));
    yield logError(
      error,
      error.response,
      "downloadReportByType",
      downloadReportURL(action.jobId, htmlLanguage)
    );
  }
}

export function* downloadReportBlobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      downloadReportBlobURL(action.jobId, action.appLanguage)
    );
    let url = response.data;

    let filename = action.jobId + ".pdf";

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadReportFileFail(action.reportType));
    yield logError(
      error,
      error.response,
      "downloadReportByType",
      downloadReportURL(action.jobId, action.reportType)
    );
  }
}

export function* tagJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      stichworte: action.word,
      farben:
        typeof action.color !== "string"
          ? JSON.stringify(action.color)
          : action.color,
      buchung: action.bookingInfo,
      ablaufInfo: action.workflowInfo
    };

    yield API.put(tagJobURL(action.id), JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json"
      }
    });
  } catch (error) {
    yield put(actions.tagJobFail());
    yield logError(error, error.response, "tagJob", tagJobURL(action.id));
  }
}

export function* saveWordTagsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.wordtags",
      datenAlsJson: action.tags
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    yield put(actions.saveWordTagsSuccess(action.tags));
  } catch (error) {
    yield put(actions.saveWordTagsFail());
    yield logError(error, error.response, "saveWordTags", saveTagsURL());
  }
}

export function* saveColorTagsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.colortags",
      datenAlsJson: action.tags
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    yield put(actions.saveColorTagsSuccess(action.tags));
  } catch (error) {
    yield put(actions.saveColorTagsFail());
    yield logError(error, error.response, "saveColorTags", saveTagsURL());
  }
}

export function* saveGridSettingsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.gridsettings",
      datenAlsJson: action.settings
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    yield put(actions.saveGridSettingsSuccess(action.settings));
  } catch (error) {
    yield put(actions.saveGridSettingsFail());
    yield logError(error, error.response, "saveGridSettings", saveTagsURL());
  }
}

export function* saveReceiverGridSettingsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "receiverjobs.ui.gridsettings",
      datenAlsJson: action.settings
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    yield put(actions.saveReceiverGridSettingsSuccess(action.settings));
  } catch (error) {
    yield put(actions.saveReceiverGridSettingsFail());
    yield logError(
      error,
      error.response,
      "saveReceiverGridSettings",
      saveTagsURL()
    );
  }
}

export function* saveSenderGroupingSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.grouping",
      datenAlsJson: JSON.stringify({ grouping: action.grouping })
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    yield put(actions.saveSenderGroupingSuccess(action.grouping));
  } catch (error) {
    yield put(actions.saveSenderGroupingFail());
    yield logError(error, error.response, "saveSenderGrouping", saveTagsURL());
  }
}

export function* saveReceiverGroupingSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "receiverjobs.ui.grouping",
      datenAlsJson: JSON.stringify({ grouping: action.grouping })
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    yield put(actions.saveReceiverGroupingSuccess(action.grouping));
  } catch (error) {
    yield put(actions.saveReceiverGroupingFail());
    yield logError(
      error,
      error.response,
      "saveReceiverGrouping",
      saveTagsURL()
    );
  }
}

export function* saveSenderSortingSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.sorting",
      datenAlsJson: JSON.stringify({ sorting: action.sorting })
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    yield put(actions.saveSenderSortingSuccess(action.sorting));
  } catch (error) {
    yield put(actions.saveSenderSortingFail());
    yield logError(error, error.response, "saveSenderSorting", saveTagsURL());
  }
}

export function* saveReceiverSortingSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "receiverjobs.ui.sorting",
      datenAlsJson: JSON.stringify({ sorting: action.sorting })
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(saveTagsURL(), stringifiedData, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    yield put(actions.saveReceiverSortingSuccess(action.sorting));
  } catch (error) {
    yield put(actions.saveReceiverSortingFail());
    yield logError(error, error.response, "saveReceiverSorting", saveTagsURL());
  }
}

export function* fetchUserPreferencesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.get(
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
    yield put(actions.fetchJobsByPreferences(response.data, action.gridType));
    //yield put(actions.setUserPreferences(response.data));
    //yield put(actions.fetchJobs());
  } catch (error) {
    yield put(actions.fetchUserPreferencesFail());
    yield logError(
      error,
      error.response,
      "fetchUserPreferences",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* setUserPreferencesAndFetchJobsSaga(action) {
  try {
    yield put(actions.setUserPreferences(action.data));
    if (action.dateTag !== null && action.dateTag !== 0) {
      let referenceDate = getJobReferenceDate(action.dateTag.toString());
      yield put(actions.fetchJobsByDate(referenceDate, action.dateTag, ""));
    } else {
      yield put(actions.fetchJobs(action.gridType));
    }
  } catch (error) {
    yield put(actions.fetchUserPreferencesFail());
    yield logError(
      error,
      error.response,
      "fetchUserPreferences",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* fetchReceiverUserPreferencesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.get(
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
    yield put(
      actions.fetchReceiverJobsByPreferences(response.data, action.gridType)
    );
    //yield put(actions.setReceiverUserPreferences(response.data));
    //yield put(actions.fetchReceiverJobs());
  } catch (error) {
    yield put(actions.fetchUserPreferencesFail());
    yield logError(
      error,
      error.response,
      "fetchReceiverUserPreferences",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* setReceiverUserPreferencesAndFetchJobsSaga(action) {
  try {
    yield put(actions.setReceiverUserPreferences(action.data));
    if (action.dateTag !== null && action.dateTag !== 0) {
      let referenceDate = getJobReferenceDate(action.dateTag.toString());
      yield put(
        actions.fetchReceiverJobsByDate(referenceDate, action.dateTag, "")
      );
    } else if (action.dateTag === 0) {
      yield put(actions.fetchReceiverJobs(action.gridType));
    } else {
      let referenceDate = getJobReferenceDate("2");
      yield put(actions.fetchReceiverJobsByDate(referenceDate, "2", ""));
    }
  } catch (error) {
    yield put(actions.fetchUserPreferencesFail());
    yield logError(
      error,
      error.response,
      "setReceiverUserPreferencesAndFetchJobsSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* deleteJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield API.delete(deleteJobURL(action.containerId, action.jobId));
    if (action.canContainerBeDeleted) {
      yield put(actions.deleteContainer(action.containerId));
    }
    yield put(actions.fetchJobs());
  } catch (error) {
    yield put(actions.deleteJobFail());
    yield logError(
      error,
      error.response,
      "deleteJob",
      deleteJobURL(action.containerId, action.jobId)
    );
  }
}

export function* deleteContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    if (action.containerId) {
      yield API.delete(deleteContainerURL(action.containerId));
    }
    yield put(actions.fetchJobs());
  } catch (error) {
    yield put(actions.deleteContainerFail());
    yield logError(
      error,
      error.response,
      "deleteContainer",
      deleteContainerURL(action.containerId)
    );
  }
}

export function* sendContainerJobListSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield put(actions.startSendingContainer());
    yield API.get(sendContainerURL(action.containerId)); //, { withCredentials: true })
    yield put(actions.checkContainerStatus(action.containerId, 1, 15));
  } catch (error) {
    yield put(actions.sendContainerJobListFailed());
    yield logError(
      error,
      error.response,
      "sendContainerJobList",
      sendContainerURL(action.containerId)
    );
  }
}

export function* checkContainerStatusSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let retries = action.retries;
    let maxRetries = action.maxRetries;

    const response = yield API.get(fetchContainerURL(action.containerId));
    if (response.data.auftraege) {
      let container = response.data.auftraege;
      let isContainerSent = true;
      container.forEach(job => {
        if (job.statusId !== 81) {
          isContainerSent = false;
        }
      });

      if (isContainerSent) {
        yield put(actions.finishSendingContainer());
      } else if (retries < maxRetries) {
        retries++;
        yield delay(2000);
        yield put(
          actions.checkContainerStatus(action.containerId, retries, maxRetries)
        );
      }
    }
  } catch (error) {
    yield logError(
      error,
      error.response,
      "checkContainerStatus",
      fetchContainerURL(action.containerId)
    );
  }
}

export function* resetJobListSaga(action) {
  try {
    yield put(actions.setSearchTerm(""));
    yield put(actions.searchJobs("", ""));
  } catch (error) {}
}

export function* resetReceiverJobListSaga(action) {
  try {
    yield put(actions.setSearchTerm(""));
    yield put(actions.searchReceiverJobs(""));
  } catch (error) {}
}

export function* fetchContainerNotesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(fetchContainerNotesURL(action.jobId));
    let jobNote = response.data.auftrag;
    let containerNote = response.data.container;

    yield put(actions.setJobListNotes(action.jobId, containerNote, jobNote));
  } catch (error) {
    // yield put(actions.fetchContainerNotesFailed(action.containerId));
    yield logError(
      error,
      error.response,
      "fetchContainerNotes",
      fetchContainerURL(action.containerId)
    );
  }
}

export function* copyJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield put(actions.startCopyingContainer());
    const response = yield API.post(copyJobURL(action.jobId), {
      erscheinungsTermin: true,
      duPdf: action.copyWithPDF,
      anmerkungen: true
    });

    if (response.data && response.data.containerId) {
      yield put(
        actions.setCopyContainerSuccess(
          response.data.containerId,
          response.data.id,
          action.jobId,
          "job"
        )
      );
    }
    yield put(actions.finishCopyingContainer());
  } catch (error) {
    yield put(actions.copyJobFailed(action.jobId));
    yield logError(error, error.response, "copyJob", copyJobURL(action.jobId));
  }
}

export function* copyContainerSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield put(actions.startCopyingContainer());
    const response = yield API.post(copyContainerURL(action.containerId), {
      optionen: action.copyWithPDF ? 31 : 27
    });

    if (response.data && response.data.id) {
      let auftraege = response.data.auftraege;
      if (auftraege.length > 0) {
        yield put(
          actions.setCopyContainerSuccess(
            response.data.id,
            auftraege[0].id,
            action.containerId,
            "container"
          )
        );
      }
    }
    yield put(actions.finishCopyingContainer());
  } catch (error) {
    yield put(actions.copyContainerFailed(action.containerId));
    yield logError(
      error,
      error.response,
      "copyContainer",
      copyContainerURL(action.containerId)
    );
  }
}

export function* exchangeJobSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    yield put(actions.startCopyingContainer());
    const response = yield API.post(copyJobURL(action.jobId), {
      erscheinungsTermin: true,
      duPdf: action.copyWithPDF,
      anmerkungen: true,
      alsAustausch: true
    });

    if (response.data && response.data.containerId) {
      let anmerkungenText =
        action.appLanguage === "en"
          ? "This is an exchange motif for DUON-ID XXXXXX. Please use this PDF for the booked advertisement."
          : "Dies ist ein Austausch-Motiv für DUON-ID " +
            action.jobId +
            ". Bitte verwenden Sie diese PDF für die gebuchte Anzeige.";

      const jobResponse = yield API.get(getJobURL(response.data.id));
      if (jobResponse !== null && jobResponse.data) {
        const jobUpdateResponse = yield API.put(getJobURL(response.data.id), {
          heftNummer: jobResponse.data.heftNummer,
          erscheinungsTermin: jobResponse.data.erscheinungsTermin,
          formatMD5Hash: jobResponse.data.formatMD5Hash,
          formatBezeichnung: jobResponse.data.formatBezeichnung,
          motiv: jobResponse.data.motiv,
          sortierung: jobResponse.data.sortierung,
          anmerkungen: anmerkungenText
        });

        if (jobUpdateResponse !== null && jobUpdateResponse.data) {
          yield put(
            actions.setExchangeJobSuccess(response.data.id, action.jobId)
          );
          yield put(
            actions.setCopyContainerSuccess(
              response.data.containerId,
              response.data.id,
              action.jobId,
              "exchange"
            )
          );
          yield put(actions.finishCopyingContainer());
        }
      }
    }
  } catch (error) {
    yield put(actions.exchangeJobFailed(action.jobId));
    yield logError(error, error.response, "copyJob", copyJobURL(action.jobId));
  }
}

export function* saveSenderDateTagSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "joblist.ui.dateTag",
      datenAlsJson: action.dateTag.toString()
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(actions.saveSenderDateTagSuccess(action.dateTag));
  } catch (error) {
    yield put(actions.saveSenderDateTagFail());
    yield logError(
      error,
      error.response,
      "saveSenderDateTag",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveReceiverDateTagSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel: "receiverjobs.ui.dateTag",
      datenAlsJson: action.dateTag.toString()
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(actions.saveReceiverDateTagSuccess(action.dateTag));
  } catch (error) {
    yield put(actions.saveReceiverDateTagFail());
    yield logError(
      error,
      error.response,
      "saveReceiverDateTag",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveStatusFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.status"
          : "joblist.ui.filters.status",
      datenAlsJson:
        action.statusFilter !== null ? action.statusFilter.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByStatusSuccess(action.jobItems, action.statusFilter)
    );
  } catch (error) {
    yield put(actions.filterByStatusFail());
    yield logError(
      error,
      error.response,
      "saveStatusFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* savePublicationStatusFilterSaga(action) {
  try {
    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.publication"
          : "joblist.ui.filters.publication",
      datenAlsJson:
        action.publishedStatusFilter !== null
          ? action.publishedStatusFilter.toString()
          : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByPublicationStatusSuccess(
        action.jobItems,
        action.publishedStatusFilter
      )
    );
  } catch (error) {
    yield put(actions.filterByPublicationStatusFail());
    yield logError(
      error,
      error.response,
      "savePublicationStatusFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveJobExchangeFilterSaga(action) {
  try {
    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.jobexchange"
          : "joblist.ui.filters.jobexchange",
      datenAlsJson:
        action.jobExchangeFilter !== null
          ? action.jobExchangeFilter.toString()
          : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByJobExchangeSuccess(
        action.jobItems,
        action.jobExchangeFilter
      )
    );
  } catch (error) {
    yield put(actions.filterByJobExchangeFail());
    yield logError(
      error,
      error.response,
      "saveJobExchangeFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveMediumFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.medium"
          : "joblist.ui.filters.medium",
      datenAlsJson:
        action.mediumFilter !== null ? action.mediumFilter.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByMediumSuccess(action.jobItems, action.mediumFilter)
    );
  } catch (error) {
    yield put(actions.filterByMediumFail());
    yield logError(
      error,
      error.response,
      "saveMediumFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveIssueFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.issue"
          : "joblist.ui.filters.issue",
      datenAlsJson:
        action.issueFilter !== null ? action.issueFilter.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByIssueSuccess(action.jobItems, action.issueFilter)
    );
  } catch (error) {
    yield put(actions.filterByIssueFail());
    yield logError(
      error,
      error.response,
      "saveIssueFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* savePreflightFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.preflight"
          : "joblist.ui.filters.preflight",
      datenAlsJson:
        action.preflightStatusFilter !== null
          ? action.preflightStatusFilter.toString()
          : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByPreflightSuccess(
        action.jobItems,
        action.preflightStatusFilter
      )
    );
  } catch (error) {
    yield put(actions.filterByPreflightFail());
    yield logError(
      error,
      error.response,
      "savePreflightFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveWordFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.word"
          : "joblist.ui.filters.word",
      datenAlsJson:
        action.wordTagFilter !== null ? action.wordTagFilter.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByWordSuccess(action.jobItems, action.wordTagFilter)
    );
  } catch (error) {
    yield put(actions.filterByWordFail());
    yield logError(
      error,
      error.response,
      "saveWordFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveColorFilterSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.filters.color"
          : "joblist.ui.filters.color",
      datenAlsJson:
        action.colorTagFilter !== null ? action.colorTagFilter.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(
      actions.filterByColorSuccess(action.jobItems, action.colorTagFilter)
    );
  } catch (error) {
    yield put(actions.filterByColorFail());
    yield logError(
      error,
      error.response,
      "saveColorFilterSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* saveViewSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let data = {
      schluessel:
        action.gridType === "receiver"
          ? "receiverjobs.ui.view"
          : "joblist.ui.view",
      datenAlsJson: action.value !== null ? action.value.toString() : ""
    };

    let stringifiedData = JSON.stringify(data);
    yield PrivateAPI.put(
      apiEndpoints.USER_PREFERENCES_ENDPOINT,
      stringifiedData,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    yield put(actions.saveViewSuccess(action.value));
  } catch (error) {
    yield put(actions.saveViewFail());
    yield logError(
      error,
      error.response,
      "saveViewSaga",
      apiEndpoints.USER_PREFERENCES_ENDPOINT
    );
  }
}

export function* fetchSenderJobContactInfoSaga(action) {

  const senderInfoUrl = senderInfoURL(
    action.senderId
  );

  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.get(senderInfoUrl);
    yield put(actions.setJobListContactInfo([response.data]));
    
  } catch (error) {
    yield put(actions.fetchSenderJobContactInfoFail());
    yield logError(
      error,
      error.response,
      "fetchSenderJobContactInfoSaga",
      senderInfoUrl
    );
  }
}

export function* fetchReceiverJobContactInfoSaga(action) {

  const technicalInfoUrl = technicalInfoURL(
    action.mediumId,
    action.erscheinungsTermin,
    action.appLanguage
  );

  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(technicalInfoUrl);

    yield put(
      actions.setJobListContactInfo(
        response.data.ansprechpartner.filter(item => item.typId === 601)
      )
    );
    
  } catch (error) {
    yield put(actions.fetchReceiverJobContactInfoFail());
    yield logError(
      error,
      error.response,
      "fetchReceiverJobContactInfoSaga",
      technicalInfoUrl
    );
  }
}

export function* requestSentJobEmailSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.post(confirmationEmailURL(action.jobId), {
      userId: action.userId,
      isEmpfangList: 0
    });

    if(response.data) {
      yield put(actions.requestJobEmailSuccess(response.data.containerOderAuftragsId, response.data.rowId));
    }
  } catch (error) {
    yield put(actions.requestJobEmailFail(action.jobId));
    yield logError(
      error,
      error.response,
      "requestSentJobEmailSaga",
      confirmationEmailURL(action.jobId, action.userId)
    );
  }
}

export function* requestReceivedJobEmailSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PrivateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.post(confirmationEmailURL(action.jobId), {
      userId: action.userId,
      isEmpfangList: 1
    });

    if(response.data) {
      yield put(actions.requestJobEmailSuccess(response.data.containerOderAuftragsId, response.data.rowId));
    }
  } catch (error) {
    yield put(actions.requestJobEmailFail(action.jobId));
    yield logError(
      error,
      error.response,
      "requestReceivedJobEmailSaga",
      confirmationEmailURL(action.jobId, action.userId)
    );
  }
}

const copyJobURL = value => {
  return apiEndpoints.COPY_JOB_ENDPOINT.replace("{id}", value);
};

const copyContainerURL = value => {
  return apiEndpoints.COPY_CONTAINER_ENDPOINT.replace("{id}", value);
};

const getJobURL = value => {
  return apiEndpoints.GET_JOB_ENDPOINT.replace("{id}", value);
};

const sendJobURL = value => {
  return apiEndpoints.SEND_JOB_ENDPOINT.replace("{id}", value);
};

const tagJobURL = id => {
  return apiEndpoints.SAVE_JOB_ENDPOINT.replace("{id}", id);
};

const saveTagsURL = () => {
  return apiEndpoints.SAVE_JOB_TAGS_ENDPOINT;
};

const deleteJobURL = (containerId, jobId) => {
  return apiEndpoints.DELETE_JOB_ENDPOINT.replace("{id}", containerId).replace(
    "{auftragsId}",
    jobId
  );
};

const downloadJobURL = (jobId, appLanguage) => {
  let url = apiEndpoints.DOWNLOAD_JOB_ENDPOINT.replace("{id}", jobId);
  url = url.replace("{sprache}", appLanguage);
  return url;
};

const downloadReportURL = (jobId, type) => {
  return apiEndpoints.DOWNLOAD_REPORT_ENDPOINT.replace("{id}", jobId).replace(
    "{format}",
    type
  );
};

const downloadReportBlobURL = (jobId, appLanguage) => {
  return appLanguage === "en"
    ? apiEndpoints.DOWNLOAD_REPORT_BLOB_EN_ENDPOINT.replace("{id}", jobId)
    : apiEndpoints.DOWNLOAD_REPORT_BLOB_DE_ENDPOINT.replace("{id}", jobId);
};

const receiveJobURL = jobId => {
  return apiEndpoints.RECEIVE_JOB_ENDPOINT.replace("{id}", jobId);
};

const deleteContainerURL = containerId => {
  return apiEndpoints.DELETE_CONTAINER_ENDPOINT.replace("{id}", containerId);
};

const sendContainerURL = value => {
  return apiEndpoints.SEND_JOB_ENDPOINT.replace("{id}", value);
};

const fetchContainerURL = containerId => {
  return apiEndpoints.CONTAINER_ENDPOINT.replace("{id}", containerId);
};

const fetchContainerNotesURL = jobId => {
  return apiEndpoints.JOB_NOTES_ENDPOINT.replace("{id}", jobId);
};

const technicalInfoURL = (mediaId, et, appLanguage) => {
  let url = apiEndpoints.TECHNICAL_INFO_ENDPOINT.replace("{id}", mediaId);
  url = url.replace("{sprache}", appLanguage);
  return url.replace("{datum}", et);
};

const senderInfoURL = (senderId) => {
  let url = apiEndpoints.FETCH_SENDER_INFO.replace("{id}", senderId);
  return url;
};

const confirmationEmailURL = (jobId, userId) => {
  let url = apiEndpoints.REQUEST_CONFIRMATION_EMAIL_ENDPOINT.replace("{id}", jobId);
  return url;
}