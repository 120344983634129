import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ScrollIntoView from 'react-scroll-into-view'
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  NavItem,
  NavLink
} from "reactstrap";
import FontAwesome from "react-fontawesome";
import Translate from "../../../../hoc/Locale/Translate";
import classes from "./LoggedOutNavigation.module.css";

class LoggedOutNavigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMediaVisited: false,
      isVerlagVisited: false,
      isJobsVisited: false,
      isUserAccountVisited: false,
      isServiceVisited: false,
      isLoginVisited: false,
      isProductVisited: false,
      isCertifiedVisited: false
    };
  }

  showServiceMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: true,
      isJobsMenuOpen: false,
      isProductMenuOpen: false
    }));
  }

  showProductMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: false,
      isJobsMenuOpen: false,
      isProductMenuOpen: true
    }));
  }

  hideMenus = () => {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: false,
      isJobsMenuOpen: false,
      isProductMenuOpen: false
    }));
  };

  setVisitedStyle = visitedLink => {
    this.props.visitLink();
    this.setState({
      isMediaVisited: false,
      isVerlagVisited: false,
      isJobsVisited: false,
      isServiceVisited: false,
      isUserAccountVisited: false,
      isLoginVisited: false,
      isProductVisited: false,
      isCertifiedVisited: false
    });

    switch (visitedLink) {
      case "medien":
        this.setState({
          isMediaVisited: true
        });
        this.hideMenus();
        break;
      case "verlage":
        this.setState({
          isVerlagVisited: true
        });
        this.hideMenus();
        break;
      case "certified":
        this.setState({
          isCertifiedVisited: true
        });
        this.hideMenus();
        break;
      case "jobs":
        this.setState({
          isJobsVisited: true
        });
        break;
      case "useraccount":
        this.setState({
          isUserAccountVisited: true
        });
        break;
      case "service":
        this.setState({
          isServiceVisited: true
        });
        break;
      case "login":
        this.setState({
          isLoginVisited: true
        });
        this.hideMenus();
        break;
      case "product":
        this.setState({
          isProductVisited: true
        });
        break;
      default:
        this.setState({
          isMediaVisited: false,
          isServiceVisited: false,
          isLoginVisited: false,
          isUserAccountVisited: false,
          isJobsVisited: false,
          isVerlagVisited: false,
          isCertifiedVisited: false
        });
    }
  };

  onSubMenuItemClick = menuItem => {
    this.setVisitedStyle(menuItem);
    this.hideMenus();
  };

  onSubMenuItemClickWithAnchor = (menuItem) => {
    this.setVisitedStyle(menuItem);
    this.hideMenus();
  };

  render() {
    const {
      isServiceMenuOpen,
      isProductMenuOpen,
      isMediaVisited,
      isVerlagVisited,
      isLoginVisited,
      isServiceVisited,
      isProductVisited,
      isCertifiedVisited
    } = this.state;
    const { appLanguage, isHomepageVisited } = this.props;
    let serviceMenuId = "ServiceMenu";
    let productMenuId = "ProductMenu";
    return (
      <>
        <NavItem
          className={
            isMediaVisited && !isHomepageVisited
              ? [classes.NavItem, classes.NavItemVisited].join(" ")
              : classes.NavItem
          }
        >
          <FontAwesome name="leanpub" />
          <NavLink
            onClick={() => this.setVisitedStyle("medien")}
            className={classes.NavLink}
            to="/medien"
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Media" />
          </NavLink>
        </NavItem>

        <NavItem
          className={
            isVerlagVisited && !isHomepageVisited
              ? [classes.NavItem, classes.NavItemVisited].join(" ")
              : classes.NavItem
          }
        >
          <FontAwesome name="building" />
          <NavLink
            onClick={() => this.setVisitedStyle("verlage")}
            className={classes.NavLink}
            to={appLanguage === "en" ? "/publishers" : "/verlage"}
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Verlage" />
          </NavLink>
        </NavItem>

        <NavItem
          className={
            isCertifiedVisited && !isHomepageVisited
              ? [classes.NavItem, classes.NavItemVisited].join(" ")
              : classes.NavItem
          }
        >
          <FontAwesome name="graduation-cap" />
          <NavLink
            onClick={() => this.setVisitedStyle("certified")}
            className={classes.NavLink}
            to="/certified"
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Certified" />
          </NavLink>
        </NavItem>

        <NavItem
          className={
            isProductVisited ? classes.NavItemVisited : classes.NavItem
          }
        >
          <FontAwesome name="paper-plane" />
          {
            <div
              id={productMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showProductMenu()}
            >
              <Translate id="Navigation.Produkt" />
            </div>
          }
        </NavItem>

        <Popover
          placement="bottom"
          isOpen={isProductMenuOpen}
          target={productMenuId}
          toggle={() => this.hideMenus()}
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
            
            <ScrollIntoView selector={"#vorteile"} scrollOptions={{behavior: "instant"}}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() =>
                    this.onSubMenuItemClickWithAnchor("product")
                  }
                  className={classes.UserMenuNavLink}
                  to="/#vorteile"
                  tag={RRNavLink}
                >
                  <FontAwesome name="plus-circle" />
                  &nbsp;&nbsp; <Translate id="Home.Vorteile" />
                </NavLink>
              </NavItem>
            </ScrollIntoView>

            <ScrollIntoView selector={"#preise"}  scrollOptions={{behavior: "instant"}} >
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() =>
                    this.onSubMenuItemClickWithAnchor("product")
                  }
                  className={classes.UserMenuNavLink}
                  to="/#preise"
                  tag={RRNavLink}
                >
                  <FontAwesome name="tag" />
                  &nbsp;&nbsp; <Translate id="Home.Preise" />
                </NavLink>
              </NavItem>
            </ScrollIntoView>

            <ScrollIntoView selector={"#teilnehmen"} scrollOptions={{behavior: "instant"}}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() =>
                    this.onSubMenuItemClickWithAnchor("product")
                  }
                  className={classes.UserMenuNavLink}
                  to="/#teilnehmen"
                  tag={RRNavLink}
                >
                  <FontAwesome name="play-circle" />
                  &nbsp;&nbsp; <Translate id="Home.Teilnehmen" />
                </NavLink>
              </NavItem>
            </ScrollIntoView>
            
            </div>
          </PopoverBody>
        </Popover>

        <NavItem
          className={
            isServiceVisited && !isHomepageVisited
              ? [classes.NavItemVisited, classes.NavItemService].join(" ")
              : [classes.NavItem, classes.NavItemService].join(" ")
          }
        >
          <FontAwesome name="user-circle-o" />
          {
            <div
              id={serviceMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showServiceMenu()}
            >
              Service
            </div>
          }
        </NavItem>
        <Popover
          placement="bottom"
          isOpen={isServiceMenuOpen}
          target={serviceMenuId}
          toggle={() => this.hideMenus()}
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="http://duon-portal.blog/"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="quote-right" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Blog" />
                </a>
              </NavItem>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="https://api.duon-portal.de/"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="wifi" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.API" />
                </a>
              </NavItem>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="https://duon.zendesk.com"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="life-saver" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Help" />
                </a>
              </NavItem>
            </div>
          </PopoverBody>
        </Popover>

        <NavItem
          className={
            isLoginVisited && !isHomepageVisited
              ? [classes.NavItemVisited, classes.NavItemLogin].join(" ")
              : [classes.NavItem, classes.NavItemLogin].join(" ")
          }
        >
          <FontAwesome name="sign-in" />
          <NavLink
            onClick={() => this.setVisitedStyle("login")}
            className={classes.NavLink}
            to="/auth"
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Login" />
          </NavLink>
        </NavItem>
      </>
    );
  }
}

LoggedOutNavigation.propTypes = {
  appLanguage: PropTypes.string,
  isHomepageVisited: PropTypes.bool,
  visitLink: PropTypes.func
};

export default LoggedOutNavigation;
