import axios from "axios";

let API_URL;


if (window.location.href.indexOf("bs-local") > -1) {
  API_URL = "https://bs-local.com:8101";
} 
else if (window.location.href.indexOf("localhost") > -1 && window.location.href.indexOf("lambdatest") > -1) {
  API_URL = "https://localhost.lambdatest.com:8101";
} 
else if (window.location.href.indexOf("localhost") > -1) {
  API_URL = "https://localhost:8101";
  //API_URL = "https://api-dev.duon-portal.de";
} else if (window.location.href.indexOf("dev.duon-portal.de") > -1) {
  API_URL = "https://api-dev.duon-portal.de";
} else if (window.location.href.indexOf("staging.duon-portal.de") > -1) {
  API_URL = "https://api-staging.duon-portal.de";
} else if (
  window.location.href.indexOf("ui-duon-staging.azurewebsites.net") > -1
) {
  API_URL = "https://api-staging.duon-portal.de";
} else if (window.location.href.indexOf("ui-duon.azurewebsites.net") > -1) {
  API_URL = "https://api-duon.azurewebsites.net";
} else {
  API_URL = "https://api.duon-portal.de";
}

const instance = axios.create({
  baseURL: API_URL ? API_URL : null
});

instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent

    const token = localStorage.getItem("token");
    if (token) {
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
