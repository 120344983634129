import { takeEvery, all } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";

import {
  authSaga,
  logoutSaga,
  checkAuthTimeoutSaga,
  authCheckStateSaga,
  fetchUserDataSaga,
  saveUserRegistrationSaga,
  saveUserRegistrationWithCompanyTicketSaga,
  confirmUserRegistrationSaga,
  setNewPasswordSaga,
  fetchUserProfileSaga,
  sendPasswordResetLinkRequestSaga,
  sendUserEmailConfirmationSaga,
  saveUserProfileSaga,
  saveUserLanguageSaga,
  hideReleaseBannerSaga,
  saveUserProfileAndPreferencesSaga,
  deleteUserProfileSaga, 
  fetchCompanyUsersSaga,
  fetchCompanyInfoSaga,
  sendCompanyInvitationSaga,
  sendCompanyInvitationAcceptanceSaga,
  fetchAssignedMediaSaga,
  fetchAssignedUsersForMediaSaga,
  saveCompanyAdminSaga,
  saveAssignedMediaSettingPerMediaSaga,
  saveAssignedMediaSettingPerUserSaga,
  downloadFileSaga,
  fetchCurrentVersionSaga,
  fetchPortalStatusSaga,
  saveUserMediaHintOptOutSaga,
  fetchCurrentMessageSaga,
  fetchSpecialMessageSaga,
  saveUserSendJobCopyAsEmailOptInSaga,
  saveUserNewsletterSaga,
  fetchUserNewslettersSaga,
  postponeCertificationOneMonthSaga,
  sendCertificationRequestSaga,
  acceptNewAGBSaga
} from "./auth";

import {
  fetchMediaItemsSaga,
  fetchMediaAzureItemsSaga,
  fetchMediaItemNamesSaga,
  fetchIssuesSaga,
  fetchDatesSaga
} from "./media";

import {
  fetchFreigabeMediaItemsSaga,
  initFreigabeDetailSaga,
  fetchFreigabeDatesSaga,
  fetchFreigabeMediaDetailSaga,
  fetchFreigabeInfoSaga,
  certifyMediumSaga,
  downloadFreigabeFileSaga,
  sendChangeRequestSaga,
  downloadFreigabeFromListSaga
} from "./freigabe";

import {
  actualizeTechnicalInfoSaga,
  addFileToExistingJobSaga,
  addFileToNewJobSaga,
  createContainerManyJobsSaga,
  createContainerOneJobSaga,
  deleteContainerJobSaga,
  deleteChildJobsSaga,
  editJobBuilderSaga,
  getJobContactPersonsSaga,
  initJobBuilderSaga,
  fetchMediaSaga,
  fetchMediaEditModeSaga,
  fetchParentMediaEditModeSaga,
  fetchJobIssuesSaga,
  fetchTechnicalDataSaga,
  fetchJobDatesSaga,
  fetchJobDatesEditModeSaga,
  fetchTechnicalInfoSaga,
  fetchTechnicalInfoEditModeSaga,
  saveNewJobWithoutFileSaga,
  saveFileSaga,
  saveJobFinishSaga,
  sendContainerSaga,
  checkJobStatusSaga,
  checkSentStatusSaga,
  fetchJobImageSaga,
  updateContainerSaga,
  proofFileSaga,
  downloadReportSaga,
  downloadPDFSaga,
  addParentOrphanJobsSaga,
  createContainerManyJobsSplitSaga,
  fetchParentJobDatesEditModeSaga,
  fetchParentTechnicalInfoEditModeSaga,
  saveExistingContainerSaga,
  saveNewContainerSaga,
  fetchJobAnonymouslySaga,
  downloadJobFileAnonymouslySaga,
  fetchSenderInfoSaga,
  proofContainerSaga
} from "./jobBuilder";

import {
  searchJobsSaga,
  searchReceiverJobsSaga,
  fetchJobsSaga,
  fetchReceiverJobsSaga,
  approveJobSaga,
  downloadJobSaga,
  downloadJobFileSaga,
  setJobAsReceivedSaga,
  downloadReportByTypeSaga,
  tagJobSaga,
  saveWordTagsSaga,
  saveColorTagsSaga,
  saveGridSettingsSaga,
  saveReceiverGridSettingsSaga,
  saveSenderGroupingSaga,
  saveReceiverGroupingSaga,
  saveSenderSortingSaga,
  saveReceiverSortingSaga,
  sendContainerJobListSaga,
  fetchUserPreferencesSaga,
  fetchReceiverUserPreferencesSaga,
  deleteJobSaga,
  deleteContainerSaga,
  checkContainerStatusSaga,
  resetJobListSaga,
  resetReceiverJobListSaga,
  fetchContainerNotesSaga,
  copyJobSaga,
  copyContainerSaga,
  exchangeJobSaga,
  downloadReportBlobSaga,
  fetchJobsByDateSaga,
  fetchReceiverJobsByDateSaga,
  fetchJobsAzureSaga,
  openReportHTMLSaga,
  saveSenderDateTagSaga,
  saveReceiverDateTagSaga,
  setUserPreferencesAndFetchJobsSaga,
  setReceiverUserPreferencesAndFetchJobsSaga,
  saveStatusFilterSaga,
  savePublicationStatusFilterSaga,
  saveJobExchangeFilterSaga,
  saveMediumFilterSaga,
  saveIssueFilterSaga,
  savePreflightFilterSaga,
  saveWordFilterSaga,
  saveColorFilterSaga,
  saveViewSaga,
  fetchReceiverJobContactInfoSaga,
  fetchSenderJobContactInfoSaga,
  requestSentJobEmailSaga,
  requestReceivedJobEmailSaga
} from "./jobList";

import {
  actualizeMediaDetailTechnicalInfoSaga,
  initMediaDetailSaga,
  initTechnicalURLSaga,
  fetchMediaDetailSaga,
  fetchMediaTechnicalInfoSaga,
  fetchCurrentMediaTechnicalInfoSaga,
  fetchTechnicalURLInfoSaga,
  downloadTechnicalInfoSaga
} from "./mediaDetail";

import { fetchJobHistorySaga } from "./jobHistory";

import { fetchCertifiedItemsSaga } from "./certifiedCompanies";

import { fetchPublishersSaga, fetchPublisherDetailSaga } from "./publisher";

export function* watchMediaDetail() {
  yield all([
    takeEvery(actionTypes.INIT_MEDIA_DETAIL, initMediaDetailSaga),
    takeEvery(actionTypes.INIT_TECHNICAL_URL, initTechnicalURLSaga),
    takeEvery(actionTypes.FETCH_MEDIA_DETAIL, fetchMediaDetailSaga),
    takeEvery(
      actionTypes.FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO,
      fetchCurrentMediaTechnicalInfoSaga
    ),
    takeEvery(
      actionTypes.FETCH_MEDIA_DETAIL_TECHNICAL_INFO,
      fetchMediaTechnicalInfoSaga
    ),
    takeEvery(actionTypes.FETCH_TECHNICAL_URL_INFO, fetchTechnicalURLInfoSaga),
    takeEvery(
      actionTypes.ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO,
      actualizeMediaDetailTechnicalInfoSaga
    ),
    takeEvery(actionTypes.DOWNLOAD_TECHNICAL_INFO, downloadTechnicalInfoSaga)
  ]);
}

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH, authSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.FETCH_USER_DATA, fetchUserDataSaga),
    takeEvery(actionTypes.REGISTERING_USER, saveUserRegistrationSaga),
    takeEvery(
      actionTypes.REGISTERING_USER_WITH_COMPANY_TICKET,
      saveUserRegistrationWithCompanyTicketSaga
    ),
    takeEvery(
      actionTypes.CONFIRM_USER_REGISTRATION,
      confirmUserRegistrationSaga
    ),
    takeEvery(
      actionTypes.SEND_PASSWORD_RESET_LINK_REQUEST,
      sendPasswordResetLinkRequestSaga
    ),
    takeEvery(actionTypes.SET_NEW_PASSWORD, setNewPasswordSaga),
    takeEvery(actionTypes.SET_NEW_PASSWORD_WITH_CODE, setNewPasswordSaga),
    takeEvery(actionTypes.FETCH_USER_PROFILE, fetchUserProfileSaga),
    takeEvery(actionTypes.FETCH_USER_NEWSLETTERS, fetchUserNewslettersSaga),
    takeEvery(
      actionTypes.SEND_EMAIL_CONFIRMATION,
      sendUserEmailConfirmationSaga
    ),
    takeEvery(actionTypes.SAVE_USER_NEWSLETTER, saveUserNewsletterSaga),
    takeEvery(actionTypes.SAVE_USER_PROFILE, saveUserProfileSaga),
    takeEvery(actionTypes.SAVE_USER_LANGUAGE, saveUserLanguageSaga),
    takeEvery(
      actionTypes.SAVE_USER_PROFILE_AND_PREFERENCES,
      saveUserProfileAndPreferencesSaga
    ),
    takeEvery(actionTypes.DELETE_USER_PROFILE, deleteUserProfileSaga),
    takeEvery(
      actionTypes.SAVE_USER_MEDIA_HINT_OPT_OUT,
      saveUserMediaHintOptOutSaga
    ),
    takeEvery(
      actionTypes.SAVE_USER_SEND_JOB_COPY_EMAIL_OPT_IN,
      saveUserSendJobCopyAsEmailOptInSaga
    ),
    takeEvery(actionTypes.FETCH_COMPANY_USERS, fetchCompanyUsersSaga),
    takeEvery(actionTypes.FETCH_COMPANY_INFO, fetchCompanyInfoSaga),
    takeEvery(actionTypes.SEND_COMPANY_INVITATION, sendCompanyInvitationSaga),
    takeEvery(
      actionTypes.ACCEPT_COMPANY_CHANGE,
      sendCompanyInvitationAcceptanceSaga
    ),
    takeEvery(actionTypes.FETCH_ASSIGNED_MEDIA, fetchAssignedMediaSaga),
    takeEvery(
      actionTypes.FETCH_ASSIGNED_USER_FOR_MEDIA,
      fetchAssignedUsersForMediaSaga
    ),
    takeEvery(actionTypes.SAVE_COMPANY_ADMIN, saveCompanyAdminSaga),
    takeEvery(
      actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_PER_MEDIA,
      saveAssignedMediaSettingPerMediaSaga
    ),
    takeEvery(
      actionTypes.SAVE_ASSIGNED_MEDIA_SETTING_PER_USER,
      saveAssignedMediaSettingPerUserSaga
    ),
    takeEvery(actionTypes.DOWNLOAD_FILE, downloadFileSaga),
    takeEvery(actionTypes.ACCEPT_NEW_AGB, acceptNewAGBSaga),

    takeEvery(actionTypes.FETCH_CURRENT_VERSION, fetchCurrentVersionSaga),
    takeEvery(actionTypes.FETCH_PORTAL_STATUS, fetchPortalStatusSaga),
    takeEvery(actionTypes.FETCH_PORTAL_MESSAGE, fetchCurrentMessageSaga),
    takeEvery(
      actionTypes.FETCH_PORTAL_MESSAGE_SPECIAL,
      fetchSpecialMessageSaga
    ),
    takeEvery(
      actionTypes.POSTPONE_CERTIFICATION_ONE_MONTH,
      postponeCertificationOneMonthSaga
    ),
    takeEvery(
      actionTypes.SEND_CERTIFICATION_REQUEST,
      sendCertificationRequestSaga
    ),
    takeEvery(actionTypes.HIDE_RELEASE_BANNER, hideReleaseBannerSaga)
  ]);
}

export function* watchMedia() {
  yield all([
    takeEvery(actionTypes.FETCH_MEDIA_ITEMS, fetchMediaItemsSaga),
    takeEvery(actionTypes.FETCH_MEDIA_AZURE_ITEMS, fetchMediaAzureItemsSaga),
    takeEvery(actionTypes.FETCH_MEDIA_ITEM_NAMES, fetchMediaItemNamesSaga),
    takeEvery(actionTypes.FETCH_ISSUES, fetchIssuesSaga),
    takeEvery(actionTypes.FETCH_DATES, fetchDatesSaga)
  ]);
}

export function* watchFreigabe() {
  yield all([
    takeEvery(
      actionTypes.FETCH_FREIGABE_MEDIA_ITEMS,
      fetchFreigabeMediaItemsSaga
    ),
    takeEvery(actionTypes.INIT_FREIGABE_DETAIL, initFreigabeDetailSaga),
    takeEvery(actionTypes.FETCH_FREIGABE_DATES, fetchFreigabeDatesSaga),
    takeEvery(
      actionTypes.FETCH_FREIGABE_MEDIA_DETAIL,
      fetchFreigabeMediaDetailSaga
    ),
    takeEvery(actionTypes.FETCH_FREIGABE_INFO, fetchFreigabeInfoSaga),
    takeEvery(actionTypes.CERTIFY_MEDIUM, certifyMediumSaga),
    takeEvery(actionTypes.DOWNLOAD_FREIGABE_FILE, downloadFreigabeFileSaga),
    takeEvery(actionTypes.SEND_CHANGE_REQUEST, sendChangeRequestSaga),
    takeEvery(
      actionTypes.DOWNLOAD_FREIGABE_FROM_LIST,
      downloadFreigabeFromListSaga
    )
  ]);
}

export function* watchJobBuilder() {
  yield all([
    takeEvery(actionTypes.ACTUALIZE_TECHNICAL_INFO, actualizeTechnicalInfoSaga),
    takeEvery(actionTypes.ADD_PARENT_ORPHAN_JOBS, addParentOrphanJobsSaga),
    takeEvery(
      actionTypes.CREATE_CONTAINER_MANY_JOBS,
      createContainerManyJobsSaga
    ),
    takeEvery(
      actionTypes.CREATE_CONTAINER_MANY_JOBS_SPLIT,
      createContainerManyJobsSplitSaga
    ),
    takeEvery(actionTypes.DELETE_JOB_JOBBUILDER, deleteContainerJobSaga),
    takeEvery(actionTypes.DELETE_CHILD_JOBS, deleteChildJobsSaga),
    takeEvery(actionTypes.EDIT_JOB_BUILDER, editJobBuilderSaga),
    takeEvery(actionTypes.GET_JOB_CONTACTPERSONS, getJobContactPersonsSaga),
    takeEvery(actionTypes.INIT_JOB_BUILDER, initJobBuilderSaga),
    takeEvery(actionTypes.FETCH_JOBBUILDER_MEDIA, fetchMediaSaga),
    takeEvery(actionTypes.FETCH_MEDIA_EDIT_MODE, fetchMediaEditModeSaga),
    takeEvery(
      actionTypes.FETCH_PARENT_MEDIA_EDIT_MODE,
      fetchParentMediaEditModeSaga
    ),
    takeEvery(actionTypes.FETCH_JOB_ISSUES, fetchJobIssuesSaga),
    takeEvery(actionTypes.FETCH_TECHNICAL_DATA, fetchTechnicalDataSaga),
    takeEvery(actionTypes.FETCH_JOB_DATES, fetchJobDatesSaga),
    takeEvery(actionTypes.FETCH_JOB_DATES_EDIT_MODE, fetchJobDatesEditModeSaga),
    takeEvery(
      actionTypes.FETCH_PARENT_JOB_DATES_EDIT_MODE,
      fetchParentJobDatesEditModeSaga
    ),
    takeEvery(actionTypes.FETCH_TECHNICAL_INFO, fetchTechnicalInfoSaga),
    takeEvery(
      actionTypes.FETCH_TECHNICAL_INFO_EDIT_MODE,
      fetchTechnicalInfoEditModeSaga
    ),
    takeEvery(
      actionTypes.FETCH_PARENT_TECHNICAL_INFO_EDIT_MODE,
      fetchParentTechnicalInfoEditModeSaga
    ),
    takeEvery(actionTypes.CREATE_CONTAINER_ONE_JOB, createContainerOneJobSaga),
    takeEvery(actionTypes.SAVE_EXISTING_CONTAINER, saveExistingContainerSaga),
    takeEvery(actionTypes.SAVE_NEW_CONTAINER, saveNewContainerSaga),
    takeEvery(actionTypes.ADD_FILE_TO_NEW_JOB, addFileToNewJobSaga),
    takeEvery(actionTypes.SAVE_FILE, saveFileSaga),
    takeEvery(actionTypes.SAVE_JOB_FINISH, saveJobFinishSaga),
    takeEvery(actionTypes.SAVE_NEW_JOB_NO_FILE, saveNewJobWithoutFileSaga),
    takeEvery(actionTypes.CHECK_JOB_STATUS, checkJobStatusSaga),
    takeEvery(actionTypes.FETCH_JOB_IMAGE, fetchJobImageSaga),
    takeEvery(actionTypes.UPDATE_CONTAINER, updateContainerSaga),
    takeEvery(actionTypes.PROOF_FILE, proofFileSaga),
    takeEvery(actionTypes.DOWNLOAD_REPORT, downloadReportSaga),
    takeEvery(actionTypes.DOWNLOAD_PDF, downloadPDFSaga),
    takeEvery(actionTypes.ADD_FILE_TO_EXISTING_JOB, addFileToExistingJobSaga),
    takeEvery(actionTypes.SEND_CONTAINER, sendContainerSaga),
    takeEvery(actionTypes.CHECK_SENT_STATUS, checkSentStatusSaga),
    takeEvery(actionTypes.FETCH_JOB_ANONYMOUSLY, fetchJobAnonymouslySaga),
    takeEvery(
      actionTypes.DOWNLOAD_JOB_FILE_ANONYMOUSLY,
      downloadJobFileAnonymouslySaga
    ),
    takeEvery(actionTypes.FETCH_SENDER_INFO, fetchSenderInfoSaga),
    takeEvery(actionTypes.PROOF_CONTAINER, proofContainerSaga)
  ]);
}

export function* watchJobList() {
  yield all([
    takeEvery(actionTypes.SEARCH_JOBS, searchJobsSaga),
    takeEvery(actionTypes.SEARCH_RECEIVER_JOBS, searchReceiverJobsSaga),
    takeEvery(actionTypes.FETCH_JOBS, fetchJobsSaga),
    takeEvery(actionTypes.FETCH_JOBS_BY_DATE, fetchJobsByDateSaga),
    takeEvery(actionTypes.FETCH_RECEIVER_JOBS, fetchReceiverJobsSaga),
    takeEvery(
      actionTypes.FETCH_RECEIVER_JOBS_BY_DATE,
      fetchReceiverJobsByDateSaga
    ),
    takeEvery("FETCH_AZURE_JOBS", fetchJobsAzureSaga),
    takeEvery(actionTypes.APPROVE_JOB, approveJobSaga),
    takeEvery(actionTypes.DOWNLOAD_JOB, downloadJobSaga),
    takeEvery(actionTypes.DOWNLOAD_JOB_FILE, downloadJobFileSaga),
    takeEvery(actionTypes.SET_JOB_AS_RECEIVED, setJobAsReceivedSaga),
    takeEvery(actionTypes.DOWNLOAD_REPORT_BY_TYPE, downloadReportByTypeSaga),
    takeEvery(actionTypes.OPEN_REPORT_HTML, openReportHTMLSaga),
    takeEvery(actionTypes.DOWNLOAD_REPORT_BLOB, downloadReportBlobSaga),
    takeEvery(actionTypes.TAG_JOB, tagJobSaga),
    takeEvery(actionTypes.SAVE_WORD_TAGS, saveWordTagsSaga),
    takeEvery(actionTypes.SAVE_COLOR_TAGS, saveColorTagsSaga),
    takeEvery(actionTypes.SAVE_GRID_SETTINGS, saveGridSettingsSaga),
    takeEvery(
      actionTypes.SAVE_RECEIVER_GRID_SETTINGS,
      saveReceiverGridSettingsSaga
    ),
    takeEvery(actionTypes.FETCH_USER_PREFERENCES, fetchUserPreferencesSaga),
    takeEvery(
      actionTypes.FETCH_RECEIVER_USER_PREFERENCES,
      fetchReceiverUserPreferencesSaga
    ),
    takeEvery(
      actionTypes.SET_USER_PREFERENCES_AND_FETCH_JOBS,
      setUserPreferencesAndFetchJobsSaga
    ),
    takeEvery(
      actionTypes.SET_RECEIVER_USER_PREFERENCES_AND_FETCH_JOBS,
      setReceiverUserPreferencesAndFetchJobsSaga
    ),
    takeEvery(actionTypes.DELETE_JOB, deleteJobSaga),
    takeEvery(actionTypes.DELETE_CONTAINER, deleteContainerSaga),
    takeEvery(actionTypes.SEND_CONTAINER_JOB_LIST, sendContainerJobListSaga),
    takeEvery(actionTypes.CHECK_CONTAINER_STATUS, checkContainerStatusSaga),
    takeEvery(actionTypes.SAVE_SENDER_GROUPING, saveSenderGroupingSaga),
    takeEvery(actionTypes.SAVE_RECEIVER_GROUPING, saveReceiverGroupingSaga),
    takeEvery(actionTypes.SAVE_SENDER_SORTING, saveSenderSortingSaga),
    takeEvery(actionTypes.SAVE_RECEIVER_SORTING, saveReceiverSortingSaga),
    takeEvery(actionTypes.SAVE_SENDER_DATETAG, saveSenderDateTagSaga),
    takeEvery(actionTypes.SAVE_RECEIVER_DATETAG, saveReceiverDateTagSaga),
    takeEvery(actionTypes.RESET_JOB_LIST, resetJobListSaga),
    takeEvery(actionTypes.RESET_RECEIVER_JOB_LIST, resetReceiverJobListSaga),
    takeEvery(actionTypes.FETCH_CONTAINER_JOB_NOTES, fetchContainerNotesSaga),
    takeEvery(actionTypes.COPY_JOB, copyJobSaga),
    takeEvery(actionTypes.COPY_CONTAINER, copyContainerSaga),
    takeEvery(actionTypes.EXCHANGE_JOB, exchangeJobSaga),
    takeEvery(actionTypes.FILTER_JOBS_BY_STATUS, saveStatusFilterSaga),
    takeEvery(
      actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS,
      savePublicationStatusFilterSaga
    ),
    takeEvery(
      actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE,
      saveJobExchangeFilterSaga
    ),
    takeEvery(actionTypes.FILTER_BY_MEDIUM, saveMediumFilterSaga),
    takeEvery(actionTypes.FILTER_BY_ISSUE, saveIssueFilterSaga),
    takeEvery(
      actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS,
      savePreflightFilterSaga
    ),
    takeEvery(actionTypes.FILTER_JOBS_BY_WORD, saveWordFilterSaga),
    takeEvery(actionTypes.FILTER_JOBS_BY_COLOR, saveColorFilterSaga),
    takeEvery(actionTypes.SAVE_VIEW, saveViewSaga),
    takeEvery(actionTypes.FETCH_RECEIVER_JOB_CONTACT_INFO, fetchReceiverJobContactInfoSaga),
    takeEvery(actionTypes.FETCH_SENDER_JOB_CONTACT_INFO, fetchSenderJobContactInfoSaga),
    takeEvery(actionTypes.REQUEST_SENT_EMAIL, requestSentJobEmailSaga),
    takeEvery(actionTypes.REQUEST_RECEIVED_EMAIL, requestReceivedJobEmailSaga)
  ]);
}

export function* watchJobHistory() {
  yield all([takeEvery(actionTypes.FETCH_JOB_HISTORY, fetchJobHistorySaga)]);
}

export function* watchDuonCertified() {
  yield all([
    takeEvery(actionTypes.FETCH_DUON_CERTIFIED, fetchCertifiedItemsSaga)
  ]);
}

export function* watchPublishers() {
  yield all([
    takeEvery(actionTypes.FETCH_PUBLISHERS, fetchPublishersSaga),
    takeEvery(actionTypes.FETCH_PUBLISHER_DETAIL, fetchPublisherDetailSaga)
  ]);
}
